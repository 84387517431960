export enum FormError {
  REQUIRED = 'required',
  INVALID_PASSWORD = 'invalid_password',
  INTEGER = 'integer',
  MIN_LENGTH = 'minlength',
  MAX_LENGTH = 'maxlength',
  MIN = 'min',
  MAX = 'max',
  PASSWORD_MISMATCH = 'password_mismatch',
  DATE_MUST_BE_GREATER_THAN = 'date_must_be_greater_than',
  DATE_MUST_BE_LESS_THAN = 'date_must_be_less_than',
  PATTERN = 'pattern',
  INVALID_EMAIL = 'invalid_email',
  EMAIL = 'email',
  INVALID_LOGIN_CREDENTIALS = 'invalid_login_credentials',
  ACCOUNT_IS_INACTIVE = 'ACCOUNT_IS_INACTIVE',
  NAME_ALREADY_IN_USE = 'NAME_ALREADY_IN_USE',
  EMAIL_ALREADY_IN_USE = 'EMAIL_ALREADY_IN_USE',
  PASSWORDS_SHOULD_BE_DIFFERENT = 'PASSWORDS_SHOULD_BE_DIFFERENT',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  NUMBER_MUST_BE_SMALLER_THAN_OR_EQUAL = 'NUMBER_MUST_BE_SMALLER_THAN_OR_EQUAL',
  NUMBER_MUST_BE_BIGGER_THAN_OR_EQUAL = 'NUMBER_MUST_BE_BIGGER_THAN_OR_EQUAL',
  MAX_FILE_SIZE = 'MAX_FILE_SIZE',
}
