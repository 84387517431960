"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Benefits = exports.Competency = exports.UnderRepresentedGroupsDescription = exports.CommitteeType = exports.TypeOfWorker = exports.ContractualRelationship = exports.ThirdPartyDescription = exports.TypeOfWork = exports.TypeOfEmployee = exports.HeatSteamUtilityType = exports.EnergyTypeReduction = exports.EnergyReductionSource = exports.EnergyBasisCalculation = exports.BusinessMetric = exports.EnergyActivityCategories = exports.EnergyDownstream = exports.EnergyUpstream = exports.EnergyActivity = exports.EnergyType = exports.EnergyOperation = exports.MaritalStatus = exports.Religion = exports.SocioeconomicStatus = exports.LGBTQIdentity = exports.VeteranStatus = exports.IndigenousStatus = exports.Nationality = exports.EducationalLevel = exports.Disability = exports.Ethnicity = exports.EmployeeCategory = exports.DiversityIndicators = exports.AgeGroups = exports.GovernanceBodyCategories = exports.DisposalOperation = exports.SiteLocation = exports.RecoveryOperation = exports.WasteComposition = exports.WasteSource = exports.WasteType = exports.WasteMaterial = exports.EmployeeFunction = exports.EmployeeLevel = exports.IllHealthTypes = exports.InjurySeverities = exports.InjuryTypes = exports.Genders = exports.WorkerTypes = exports.AuditTypes = exports.WorkerCategories = void 0;
exports.ImpactMagnitude = exports.Likelihoods = exports.Impacts = exports.TimeFrame = exports.RiskOpportunityTypes = exports.Duration = exports.TypeOfAssistance = exports.ParticipationLevel = exports.FundingStatus = exports.PlanCoverage = exports.TypeOfBenefitPlan = exports.TypeOfLegalAction = exports.WorkerCategory = void 0;
var WorkerCategories;
(function (WorkerCategories) {
  WorkerCategories["EMPLOYEE"] = "EMPLOYEE";
  WorkerCategories["CONTROLLED_WORKER"] = "CONTROLLED-WORKER";
})(WorkerCategories || (exports.WorkerCategories = WorkerCategories = {}));
var AuditTypes;
(function (AuditTypes) {
  AuditTypes["INTERNAL"] = "INTERNAL";
  AuditTypes["EXTERNAL"] = "EXTERNAL";
})(AuditTypes || (exports.AuditTypes = AuditTypes = {}));
var WorkerTypes;
(function (WorkerTypes) {
  WorkerTypes["EMPLOYEE"] = "EMPLOYEE";
  WorkerTypes["NON_EMPLOYEE"] = "NON-EMPLOYEE";
})(WorkerTypes || (exports.WorkerTypes = WorkerTypes = {}));
var Genders;
(function (Genders) {
  Genders["MALE"] = "MALE";
  Genders["FEMALE"] = "FEMALE";
  Genders["OTHERS"] = "OTHERS";
})(Genders || (exports.Genders = Genders = {}));
var InjuryTypes;
(function (InjuryTypes) {
  InjuryTypes["FATALITY"] = "FATALITY";
  InjuryTypes["HIGH_CONSEQUENCE"] = "HIGH-CONSEQUENCE";
  InjuryTypes["RECORDABLE"] = "RECORDABLE";
})(InjuryTypes || (exports.InjuryTypes = InjuryTypes = {}));
var InjurySeverities;
(function (InjurySeverities) {
  InjurySeverities["MILD"] = "MILD";
  InjurySeverities["MODERATE"] = "MODERATE";
  InjurySeverities["SEVERE"] = "SEVERE";
  InjurySeverities["FATAL"] = "FATAL";
})(InjurySeverities || (exports.InjurySeverities = InjurySeverities = {}));
var IllHealthTypes;
(function (IllHealthTypes) {
  IllHealthTypes["RESPIRATORY_CONDITIONS"] = "RESPIRATORY_CONDITIONS";
  IllHealthTypes["MUSCULOSKELETAL_DISORDERS"] = "MUSCULOSKELETAL_DISORDERS";
  IllHealthTypes["SKIN_DISEASES"] = "SKIN_DISEASES";
  IllHealthTypes["HEARING_LOSS"] = "HEARING_LOSS";
  IllHealthTypes["PSYCHOLOGICAL_DISORDERS"] = "PSYCHOLOGICAL_DISORDERS";
  IllHealthTypes["CARDIOVASCULAR_DISORDERS"] = "CARDIOVASCULAR_DISEASES";
  IllHealthTypes["GASTROINTESTINAL_DISORDERS"] = "GASTROINTESTINAL_DISORDERS";
  IllHealthTypes["VISION_IMPAIRMENT"] = "VISION_IMPAIRMENT";
  IllHealthTypes["OCCUPATIONAL_DISORDERS"] = "OCCUPATIONAL_CANCERS";
  IllHealthTypes["INFECTIOUS_DISORDERS"] = "INFECTIOUS_DISEASES";
})(IllHealthTypes || (exports.IllHealthTypes = IllHealthTypes = {}));
var EmployeeLevel;
(function (EmployeeLevel) {
  EmployeeLevel["EXECUTIVE_LEVEL"] = "EXECUTIVE_LEVEL";
  EmployeeLevel["SENIOR_MANAGEMENT"] = "SENIOR_MANAGEMENT";
  EmployeeLevel["MIDDLE_MANAGEMENT"] = "MIDDLE_MANAGEMENT";
  EmployeeLevel["JUNIOR_MANAGEMENT"] = "JUNIOR_MANAGEMENT";
  EmployeeLevel["PROFESSIONAL_STAFF"] = "PROFESSIONAL_STAFF";
  EmployeeLevel["TECHNICAL_STAFF"] = "TECHNICAL_STAFF";
  EmployeeLevel["ADMINISTRATIVE_STAFF"] = "ADMINISTRATIVE_STAFF";
  EmployeeLevel["OPERATIONAL_STAFF"] = "OPERATIONAL_STAFF";
  EmployeeLevel["ENTRY_LEVEL_STAFF"] = "ENTRY_LEVEL_STAFF";
})(EmployeeLevel || (exports.EmployeeLevel = EmployeeLevel = {}));
var EmployeeFunction;
(function (EmployeeFunction) {
  EmployeeFunction["TECHNICAL"] = "TECHNICAL";
  EmployeeFunction["ADMINISTRATIVE"] = "ADMINISTRATIVE";
  EmployeeFunction["PRODUCTION"] = "PRODUCTION";
  EmployeeFunction["SALES"] = "SALES";
  EmployeeFunction["MARKETING"] = "MARKETING";
  EmployeeFunction["HUMAN_RESOURCES"] = "HUMAN_RESOURCES";
  EmployeeFunction["FINANCE"] = "FINANCE";
  EmployeeFunction["CUSTOMER_SERVICE"] = "CUSTOMER_SERVICE";
  EmployeeFunction["RESEARCH_AND_DEVELOPMENT"] = "RESEARCH_AND_DEVELOPMENT";
  EmployeeFunction["IT_SUPPORT"] = "IT_SUPPORT";
  EmployeeFunction["LEGAL"] = "LEGAL";
  EmployeeFunction["LOGISTICS"] = "LOGISTICS";
})(EmployeeFunction || (exports.EmployeeFunction = EmployeeFunction = {}));
var WasteMaterial;
(function (WasteMaterial) {
  WasteMaterial["FABRIC"] = "Fabric";
  WasteMaterial["USED_OIL"] = "Used Oil";
  WasteMaterial["PACKAGING_WASTE"] = "Packaging Waste";
  WasteMaterial["BATTERIES"] = "Batteries";
  WasteMaterial["OTHER"] = "Other (values to be specified later)";
  WasteMaterial["PLASTIC_WASTE"] = "Plastic Waste";
  WasteMaterial["E_WASTE"] = "E-Waste";
  WasteMaterial["BATTERY_WASTE_LEAD_ACID_NI_CD_DRY_CELL"] = "Battery Waste (Lead Acid + Ni-Cd + Dry Cell)";
  WasteMaterial["CONSTRUCTION_WASTE"] = "Construction Waste";
  WasteMaterial["BIO_MEDICAL_WASTE"] = "Bio-Medical Waste";
  WasteMaterial["RADIOACTIVE_WASTE"] = "Radioactive Waste";
  WasteMaterial["CANTEEN_FOOD_WASTE_COMPOSTING"] = "Canteen Food Waste (Composting)";
  WasteMaterial["PAPER_WASTE_CARTONS"] = "Paper Waste Cartons";
  WasteMaterial["INSULATION_WASTE"] = "Insulation Waste";
  WasteMaterial["SPUN_FILTERS"] = "Spun Filters";
  WasteMaterial["USED_OR_SPENT_OIL"] = "5.1 Used or Spent Oil";
  WasteMaterial["WASTE_OR_RESIDUES_CONTAINING_OIL"] = "5.2 Waste or Residues Containing Oil";
  WasteMaterial["SPENT_CATALYST"] = "18.1 Spent Catalyst";
  WasteMaterial["EMPTY_BARRELS_CONTAINERS_LINERS_CONTAMINATED_WITH_HAZ_CHEMICALS_WASTES"] = "33.1 Empty Barrels / Containers / Liners Contaminated with Haz Chemicals / Wastes";
  WasteMaterial["KMNO4_SLUDGE_PROCESS_RESIDUE"] = "35.1 KMnO4 Sludge / Process Residue";
  WasteMaterial["SPRAY_CANS"] = "33.1 Spray Cans";
  WasteMaterial["USED_CONTAINERS"] = "33.1 Used Containers";
  WasteMaterial["SPENT_PLATINUM_RHODIUM_CATALYST"] = "17.2 Spent Platinum Rhodium Catalyst";
  WasteMaterial["SPENT_USED_DENOX_CATALYST"] = "17.2 Spent/Used DeNOx Catalyst";
  WasteMaterial["SPENT_ION_EXCHANGE_RESINS_DM_RESIN"] = "35.2 Spent Ion Exchange Resins (DM Resin)";
  WasteMaterial["USED_OIL_FILTER_NON_METALLIC"] = "5.2 Used Oil Filter (non-Metallic)";
  WasteMaterial["COAL_ASH"] = "Coal Ash";
  WasteMaterial["ASH_DUE_TO_COAL_LIME_TREATMENT"] = "Ash due to Coal & Lime treatment";
  WasteMaterial["DISCARDED_CONTAINERS_LINERS"] = "33.1 Discarded Containers / Liners";
  WasteMaterial["CHEMICAL_SLUDGE_FROM_WASTE_WATER_TREATMENT"] = "35.3 Chemical Sludge from Waste Water Treatment";
  WasteMaterial["CHEMICAL_CONTAINING_RESIDUE_FROM_DECONTAMINATION"] = "34.1 Chemical-Containing residue arising from decontamination";
  WasteMaterial["IRON_OXIDE_CATALYST"] = "17.2 Iron Oxide Catalyst";
  WasteMaterial["NICKEL_CATALYST_FROM_REFORMER"] = "17.2 Nickel Catalyst from Reformer";
  WasteMaterial["ZINC_OXIDE"] = "17.2 Zinc Oxide";
  WasteMaterial["SPENT_ION_EXCHANGE_RESINS"] = "35.2 Spent Ion Exchange Resins";
  WasteMaterial["SPENT_OIL_DRUMS"] = "Spent Oil Drums";
  WasteMaterial["MIX_SCRAP_METAL"] = "Mix Scrap (Metal)";
  WasteMaterial["CHEMICAL_TREATED_WOOD"] = "Chemical Treated Wood";
  WasteMaterial["PVC_FRILLS"] = "PVC Frills";
  WasteMaterial["MS_SCRAP"] = "MS Scrap";
  WasteMaterial["WOODEN_SCRAP"] = "Wooden Scrap";
  WasteMaterial["RAW_MATERIAL_CONTAMINATED_BAGS_PLASTIC"] = "Raw Material Contaminated Bags (Plastic)";
  WasteMaterial["SCRAP_GI"] = "Scrap GI";
  WasteMaterial["MS_DRUMS_DAMAGED_RUSTED"] = "MS Drums Damaged/Rusted";
  WasteMaterial["ALUMINIUM_BODY_FILTERS"] = "Aluminium Body Filters";
  WasteMaterial["EMPTY_CARBOYS"] = "Empty Carboys";
  WasteMaterial["GI_BODY_FILTERS"] = "GI Body Filters";
  WasteMaterial["EMPTY_TORN_BAGS_SCRAP"] = "Empty Torn Bags Scrap";
  WasteMaterial["ALUMINIUM_SCRAP"] = "Aluminium Scrap";
  WasteMaterial["SS_BODY_FILTERS"] = "SS Body Filters";
  WasteMaterial["RUBBER_SCRAP"] = "Rubber Scrap";
  WasteMaterial["SCRAP_HDPE_PVC_DRUMS"] = "Scrap HDPE/PVC Drums";
  WasteMaterial["SS_SCRAP"] = "SS Scrap";
  WasteMaterial["SCRAP_MIX_CABLE"] = "Scrap Mix Cable";
  WasteMaterial["MS_DRUMS_210_LITR_CAP_CLOSED_MOUTH"] = "MS Drums 210 Litr. Cap. Closed Mouth";
  WasteMaterial["MS_DRUMS_210_LITR_CAP_OPEN_MOUTH"] = "MS Drums 210 Litr. Cap. Open Mouth";
  WasteMaterial["SCRAP_EMPTY_TORRENT_CONTAINERS"] = "Scrap Empty Torrent Containers";
  WasteMaterial["PAPER_SCRAP"] = "Paper Scrap";
  WasteMaterial["STORES_SCRAP_GARBAGE"] = "Stores - Scrap & Garbage";
  WasteMaterial["ADMIN_SCRAP_GARBAGE"] = "Admin - Scrap & Garbage";
  WasteMaterial["BIOLOGICAL_SLUDGE_FROM_WASTE_WATER_TREATMENT"] = "Biological Sludge from Waste Water Treatment";
  WasteMaterial["ABSORB_GEL_FROM_LDAN_BAGGING"] = "34.1 Absorb Gel from LDAN Bagging";
  WasteMaterial["OIL_SOAKED_COTTON_WASTE"] = "5.2 Oil Soaked cotton waste";
  WasteMaterial["SPENT_ION_EXCHANGE_RESIN_CONTAINING_TOXIC_METALS"] = "35.2 Spent Ion Exchange Resin containing Toxic Metals";
  WasteMaterial["METALLIC_WASTE_IRON_STEEL_ALUMINIUM_COPPER"] = "Metallic Waste (Iron, Steel, Aluminium, Copper)";
  WasteMaterial["METALLIC_FILTERS"] = "Metallic Filters";
  WasteMaterial["WOOD_WASTE"] = "Wood Waste";
  WasteMaterial["RUBBER_WASTE"] = "Rubber Waste";
  WasteMaterial["BAGS"] = "Bags";
  WasteMaterial["PAPER"] = "Paper";
  WasteMaterial["DRUMS_CONTAINERS"] = "Drums & Container (that are not reported above)";
  WasteMaterial["USED_SPENT_OIL"] = "5.1 Used/Spent Oil";
  WasteMaterial["USED_OIL_FILTERS"] = "5.2 Used oil filters";
  WasteMaterial["MIXED_GARBAGE"] = "Mixed Garbages (including paper, packing material, thermocoal)";
  WasteMaterial["STP_SLUDGE"] = "STP Sludge";
})(WasteMaterial || (exports.WasteMaterial = WasteMaterial = {}));
var WasteType;
(function (WasteType) {
  WasteType["HAZARDOUS"] = "Hazardous";
  WasteType["NON_HAZARDOUS"] = "Non-Hazardous";
  WasteType["PLASTIC_WASTE"] = "Plastic Waste";
  WasteType["E_WASTE"] = "E-Waste";
  WasteType["BATTERY_WASTE"] = "Battery Waste";
  WasteType["CONSTRUCTION_WASTE"] = "Construction Waste";
  WasteType["BIO_MEDICAL_WASTE"] = "Bio-Medical Waste";
  WasteType["RADIOACTIVE_WASTE"] = "Radioactive Waste";
  WasteType["SCRAP"] = "Scrap";
})(WasteType || (exports.WasteType = WasteType = {}));
var WasteSource;
(function (WasteSource) {
  WasteSource["OWN_ACTIVITIES"] = "Own Activities";
  WasteSource["UPSTREAM_ACTIVITIES"] = "Upstream Activities";
  WasteSource["DOWNSTREAM_ACTIVITIES"] = "Downstream Activities";
})(WasteSource || (exports.WasteSource = WasteSource = {}));
var WasteComposition;
(function (WasteComposition) {
  WasteComposition["BIOMASS"] = "Biomass";
  WasteComposition["PLASTICS"] = "Plastics";
  WasteComposition["TEXTILES"] = "Textiles";
  WasteComposition["METALS"] = "Metals";
  WasteComposition["MON_METALLIC_MINERALS"] = "Mon-metallic minerals";
})(WasteComposition || (exports.WasteComposition = WasteComposition = {}));
var RecoveryOperation;
(function (RecoveryOperation) {
  RecoveryOperation["REUSE"] = "Reuse";
  RecoveryOperation["RECYCLE"] = "Recycle";
  RecoveryOperation["OTHER"] = "Other ";
})(RecoveryOperation || (exports.RecoveryOperation = RecoveryOperation = {}));
var SiteLocation;
(function (SiteLocation) {
  SiteLocation["ONSITE"] = "Onsite";
  SiteLocation["OFFSITE"] = "Offsite";
})(SiteLocation || (exports.SiteLocation = SiteLocation = {}));
var DisposalOperation;
(function (DisposalOperation) {
  DisposalOperation["INCINERATION_WITH_ENERGY_RECOVERY"] = "Incineration (with energy recovery)";
  DisposalOperation["INCINERATION_WITHOUT_ENERGY_RECOVERY"] = "Incineration (without energy recovery)";
  DisposalOperation["LAND_FILLING"] = "Land filling";
  DisposalOperation["DUMPING"] = "Dumping";
  DisposalOperation["OPEN_BURNING"] = "Open Burning";
  DisposalOperation["DEEP_WELL_INJECTION"] = "Deep Well Injection";
  DisposalOperation["OTHER"] = "Other ";
})(DisposalOperation || (exports.DisposalOperation = DisposalOperation = {}));
var GovernanceBodyCategories;
(function (GovernanceBodyCategories) {
  GovernanceBodyCategories["BOARD_OF_DIRECTORS"] = "BOARD_OF_DIRECTORS";
  GovernanceBodyCategories["EXECUTIVE_MANAGEMENT_TEAM"] = "EXECUTIVE_MANAGEMENT_TEAM";
  GovernanceBodyCategories["ADVISORY_COMMITTEES"] = "ADVISORY_COMMITTEES";
  GovernanceBodyCategories["AUDIT_COMMITTEE"] = "AUDIT_COMMITTEE";
  GovernanceBodyCategories["COMPENSATION_COMMITTEE"] = "COMPENSATION_COMMITTEE";
  GovernanceBodyCategories["NOMINATIONS_COMMITTEE"] = "NOMINATIONS_COMMITTEE";
  GovernanceBodyCategories["CORPORATE_GOVERNANCE_COMMITTEE"] = "CORPORATE_GOVERNANCE_COMMITTEE";
  GovernanceBodyCategories["ETHICS_OR_COMPLIANCE_COMMITTEE"] = "ETHICS_OR_COMPLIANCE_COMMITTEE";
  GovernanceBodyCategories["RISK_MANAGEMENT_COMMITTEE"] = "RISK_MANAGEMENT_COMMITTEE";
  GovernanceBodyCategories["SUSTAINABILITY_CSR_COMMITTEE"] = "SUSTAINABILITY_CSR_COMMITTEE";
  GovernanceBodyCategories["SPECIALIZED_COMMITTEES"] = "SPECIALIZED_COMMITTEES";
})(GovernanceBodyCategories || (exports.GovernanceBodyCategories = GovernanceBodyCategories = {}));
var AgeGroups;
(function (AgeGroups) {
  AgeGroups["UNDER"] = "UNDER";
  AgeGroups["MID"] = "MID";
  AgeGroups["OVER"] = "OVER";
})(AgeGroups || (exports.AgeGroups = AgeGroups = {}));
var DiversityIndicators;
(function (DiversityIndicators) {
  DiversityIndicators["ETHNICITY"] = "ETHNICITY";
  DiversityIndicators["DISABILITY"] = "DISABILITY";
  DiversityIndicators["EDUCATIONAL_LEVEL"] = "EDUCATIONAL_LEVEL";
  DiversityIndicators["NATIONALITY"] = "NATIONALITY";
  DiversityIndicators["INDIGENOUS_STATUS"] = "INDIGENOUS_STATUS";
  DiversityIndicators["VETERAN_STATUS"] = "VETERAN_STATUS";
  DiversityIndicators["LGBTQ_IDENTITY"] = "LGBTQ_IDENTITY";
  DiversityIndicators["SOCIOECONOMIC_STATUS"] = "SOCIOECONOMIC_STATUS";
  DiversityIndicators["RELIGION"] = "RELIGION";
  DiversityIndicators["MARITAL_STATUS"] = "MARITAL_STATUS";
})(DiversityIndicators || (exports.DiversityIndicators = DiversityIndicators = {}));
var EmployeeCategory;
(function (EmployeeCategory) {
  EmployeeCategory["SENIOR_MANAGEMENT"] = "SENIOR_MANAGEMENT";
  EmployeeCategory["MIDDLE_MANAGEMENT"] = "MIDDLE_MANAGEMENT";
  EmployeeCategory["PROFESSIONAL"] = "PROFESSIONAL";
  EmployeeCategory["TECHNICAL"] = "TECHNICAL";
  EmployeeCategory["ADMINISTRATIVE"] = "ADMINISTRATIVE";
  EmployeeCategory["OPERATIONAL"] = "OPERATIONAL";
  EmployeeCategory["SALES"] = "SALES";
  EmployeeCategory["CUSTOMER_SERVICE"] = "CUSTOMER_SERVICE";
  EmployeeCategory["LABOR"] = "LABOR";
  EmployeeCategory["INTERN_TRAINEE"] = "INTERN_TRAINEE";
})(EmployeeCategory || (exports.EmployeeCategory = EmployeeCategory = {}));
var Ethnicity;
(function (Ethnicity) {
  Ethnicity["HISPANIC_OR_LATINO"] = "HISPANIC_OR_LATINO";
  Ethnicity["BLACK_OR_AFRICAN_AMERICAN"] = "BLACK_OR_AFRICAN_AMERICAN";
  Ethnicity["WHITE"] = "WHITE";
  Ethnicity["ASIAN"] = "ASIAN";
  Ethnicity["NATIVE_AMERICAN_OR_ALASKA_NATIVE"] = "NATIVE_AMERICAN_OR_ALASKA_NATIVE";
  Ethnicity["NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER"] = "NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER";
  Ethnicity["TWO_OR_MORE_RACES"] = "TWO_OR_MORE_RACES";
  Ethnicity["OTHER"] = "OTHER";
})(Ethnicity || (exports.Ethnicity = Ethnicity = {}));
var Disability;
(function (Disability) {
  Disability["NO_DISABILITY"] = "NO_DISABILITY";
  Disability["VISUAL_IMPAIRMENT"] = "VISUAL_IMPAIRMENT";
  Disability["HEARING_IMPAIRMENT"] = "HEARING_IMPAIRMENT";
  Disability["PHYSICAL_DISABILITY"] = "PHYSICAL_DISABILITY";
  Disability["COGNITIVE_DISABILITY"] = "COGNITIVE_DISABILITY";
  Disability["MENTAL_HEALTH_CONDITION"] = "MENTAL_HEALTH_CONDITION";
  Disability["NEURODIVERSITY"] = "NEURODIVERSITY";
  Disability["SPEECH_IMPAIRMENT"] = "SPEECH_IMPAIRMENT";
  Disability["INVISIBLE_DISABILITY"] = "INVISIBLE_DISABILITY";
})(Disability || (exports.Disability = Disability = {}));
var EducationalLevel;
(function (EducationalLevel) {
  EducationalLevel["NO_FORMAL_EDUCATION"] = "NO_FORMAL_EDUCATION";
  EducationalLevel["PRIMARY_EDUCATION"] = "PRIMARY_EDUCATION";
  EducationalLevel["SECONDARY_EDUCATION"] = "SECONDARY_EDUCATION";
  EducationalLevel["VOCATIONAL_TRAINING"] = "VOCATIONAL_TRAINING";
  EducationalLevel["ASSOCIATE_DEGREE"] = "ASSOCIATE_DEGREE";
  EducationalLevel["BACHELORS_DEGREE"] = "BACHELORS_DEGREE";
  EducationalLevel["MASTERS_DEGREE"] = "MASTERS_DEGREE";
  EducationalLevel["DOCTORAL_DEGREE"] = "DOCTORAL_DEGREE";
  EducationalLevel["PROFESSIONAL_DEGREE"] = "PROFESSIONAL_DEGREE";
})(EducationalLevel || (exports.EducationalLevel = EducationalLevel = {}));
var Nationality;
(function (Nationality) {
  Nationality["AMERICAN"] = "AMERICAN";
  Nationality["CANADIAN"] = "CANADIAN";
  Nationality["BRITISH"] = "BRITISH";
  Nationality["FRENCH"] = "FRENCH";
  Nationality["GERMAN"] = "GERMAN";
  Nationality["INDIAN"] = "INDIAN";
  Nationality["CHINESE"] = "CHINESE";
  Nationality["BRAZILIAN"] = "BRAZILIAN";
  Nationality["SOUTH_AFRICAN"] = "SOUTH_AFRICAN";
  Nationality["JAPANESE"] = "JAPANESE";
})(Nationality || (exports.Nationality = Nationality = {}));
var IndigenousStatus;
(function (IndigenousStatus) {
  IndigenousStatus["INDIGENOUS"] = "INDIGENOUS";
  IndigenousStatus["NON_INDIGENOUS"] = "NON_INDIGENOUS";
  IndigenousStatus["PREFER_NOT_TO_SAY"] = "PREFER_NOT_TO_SAY";
})(IndigenousStatus || (exports.IndigenousStatus = IndigenousStatus = {}));
var VeteranStatus;
(function (VeteranStatus) {
  VeteranStatus["VETERAN"] = "VETERAN";
  VeteranStatus["NON_VETERAN"] = "NON_VETERAN";
  VeteranStatus["ACTIVE_DUTY"] = "ACTIVE_DUTY";
  VeteranStatus["RESERVE"] = "RESERVE";
  VeteranStatus["PREFER_NOT_TO_SAY"] = "PREFER_NOT_TO_SAY";
})(VeteranStatus || (exports.VeteranStatus = VeteranStatus = {}));
var LGBTQIdentity;
(function (LGBTQIdentity) {
  LGBTQIdentity["IDENTIFIES_AS_LGBTQ_PLUS"] = "IDENTIFIES_AS_LGBTQ_PLUS";
  LGBTQIdentity["DOES_NOT_IDENTIFY_AS_LGBTQ_PLUS"] = "DOES_NOT_IDENTIFY_AS_LGBTQ_PLUS";
  LGBTQIdentity["PREFER_NOT_TO_SAY"] = "PREFER_NOT_TO_SAY";
})(LGBTQIdentity || (exports.LGBTQIdentity = LGBTQIdentity = {}));
var SocioeconomicStatus;
(function (SocioeconomicStatus) {
  SocioeconomicStatus["LOW_SES"] = "LOW_SES";
  SocioeconomicStatus["MIDDLE_SES"] = "MIDDLE_SES";
  SocioeconomicStatus["HIGH_SES"] = "HIGH_SES";
  SocioeconomicStatus["PREFER_NOT_TO_SAY"] = "PREFER_NOT_TO_SAY";
})(SocioeconomicStatus || (exports.SocioeconomicStatus = SocioeconomicStatus = {}));
var Religion;
(function (Religion) {
  Religion["CHRISTIANITY"] = "CHRISTIANITY";
  Religion["ISLAM"] = "ISLAM";
  Religion["HINDUISM"] = "HINDUISM";
  Religion["BUDDHISM"] = "BUDDHISM";
  Religion["JUDAISM"] = "JUDAISM";
  Religion["SIKHISM"] = "SIKHISM";
  Religion["ATHEISM"] = "ATHEISM";
  Religion["AGNOSTICISM"] = "AGNOSTICISM";
  Religion["OTHER"] = "OTHER";
  Religion["PREFER_NOT_TO_SAY"] = "PREFER_NOT_TO_SAY";
})(Religion || (exports.Religion = Religion = {}));
var MaritalStatus;
(function (MaritalStatus) {
  MaritalStatus["SINGLE"] = "SINGLE";
  MaritalStatus["MARRIED"] = "MARRIED";
  MaritalStatus["DIVORCED"] = "DIVORCED";
  MaritalStatus["WIDOWED"] = "WIDOWED";
  MaritalStatus["SEPARATED"] = "SEPARATED";
  MaritalStatus["IN_A_PARTNERSHIP"] = "IN_A_PARTNERSHIP";
  MaritalStatus["PREFER_NOT_TO_SAY"] = "PREFER_NOT_TO_SAY";
})(MaritalStatus || (exports.MaritalStatus = MaritalStatus = {}));
var EnergyOperation;
(function (EnergyOperation) {
  EnergyOperation["CONSUMED"] = "CONSUMED";
  EnergyOperation["SOLD"] = "SOLD";
})(EnergyOperation || (exports.EnergyOperation = EnergyOperation = {}));
var EnergyType;
(function (EnergyType) {
  EnergyType["ELECTRICITY"] = "ELECTRICITY";
  EnergyType["HEATING"] = "HEATING";
  EnergyType["COOLING"] = "COOLING";
  EnergyType["STEAM"] = "STEAM";
})(EnergyType || (exports.EnergyType = EnergyType = {}));
var EnergyActivity;
(function (EnergyActivity) {
  EnergyActivity["UPSTREAM"] = "UPSTREAM";
  EnergyActivity["DOWNSTREAM"] = "DOWNSTREAM";
})(EnergyActivity || (exports.EnergyActivity = EnergyActivity = {}));
var EnergyUpstream;
(function (EnergyUpstream) {
  EnergyUpstream["PURCHASED_GOODS_AND_SERVICES"] = "PURCHASED_GOODS_AND_SERVICES";
  EnergyUpstream["CAPITAL_GOODS"] = "CAPITAL_GOODS";
  EnergyUpstream["FUEL_AND_ENERGY_RELATED_ACTIVITIES"] = "FUEL_AND_ENERGY_RELATED_ACTIVITIES";
  EnergyUpstream["UPSTREAM_TRANSPORTATION_AND_DISTRIBUTION"] = "UPSTREAM_TRANSPORTATION_AND_DISTRIBUTION";
  EnergyUpstream["WASTE_GENERATED_IN_OPERATIONS"] = "WASTE_GENERATED_IN_OPERATIONS";
  EnergyUpstream["BUSINESS_TRAVEL"] = "BUSINESS_TRAVEL";
  EnergyUpstream["EMPLOYEE_COMMUTING"] = "EMPLOYEE_COMMUTING";
  EnergyUpstream["UPSTREAM_LEASED_ASSETS"] = "UPSTREAM_LEASED_ASSETS";
})(EnergyUpstream || (exports.EnergyUpstream = EnergyUpstream = {}));
var EnergyDownstream;
(function (EnergyDownstream) {
  EnergyDownstream["DOWNSTREAM_TRANSPORTATION_AND_DISTRIBUTION"] = "DOWNSTREAM_TRANSPORTATION_AND_DISTRIBUTION";
  EnergyDownstream["PROCESSING_OF_SOLD_PRODUCTS"] = "PROCESSING_OF_SOLD_PRODUCTS";
  EnergyDownstream["USE_OF_SOLD_PRODUCTS"] = "USE_OF_SOLD_PRODUCTS";
  EnergyDownstream["END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS"] = "END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS";
  EnergyDownstream["DOWNSTREAM_LEASED_ASSETS"] = "DOWNSTREAM_LEASED_ASSETS";
  EnergyDownstream["FRANCHISES"] = "FRANCHISES";
  EnergyDownstream["INVESTMENTS"] = "INVESTMENTS";
})(EnergyDownstream || (exports.EnergyDownstream = EnergyDownstream = {}));
exports.EnergyActivityCategories = {
  ...EnergyUpstream,
  ...EnergyDownstream
};
var BusinessMetric;
(function (BusinessMetric) {
  BusinessMetric["PRODUCTION_VOLUME"] = "PRODUCTION_VOLUME";
  BusinessMetric["SIZE"] = "SIZE";
  BusinessMetric["NUMBER_OF_FULL_TIME_EMPLOYEES"] = "NUMBER_OF_FULL_TIME_EMPLOYEES";
  BusinessMetric["MONETARY_UNITS"] = "MONETARY_UNITS";
  BusinessMetric["SERVICES_PROVIDED"] = "SERVICES_PROVIDED";
  BusinessMetric["DISTANCE_TRAVELED"] = "DISTANCE_TRAVELED";
  BusinessMetric["OPERATIONAL_HOURS"] = "OPERATIONAL_HOURS";
  BusinessMetric["ENERGY_CONSUMPTION_PER_FLOOR_AREA"] = "ENERGY_CONSUMPTION_PER_FLOOR_AREA";
  BusinessMetric["ENERGY_CONSUMPTION_PER_EMPLOYEE"] = "ENERGY_CONSUMPTION_PER_EMPLOYEE";
  BusinessMetric["ENERGY_CONSUMPTION_PER_PRODUCT"] = "ENERGY_CONSUMPTION_PER_PRODUCT";
  BusinessMetric["ENERGY_CONSUMPTION_PER_REVENUE"] = "ENERGY_CONSUMPTION_PER_REVENUE";
})(BusinessMetric || (exports.BusinessMetric = BusinessMetric = {}));
var EnergyBasisCalculation;
(function (EnergyBasisCalculation) {
  EnergyBasisCalculation["BASE_YEAR"] = "BASE_YEAR";
  EnergyBasisCalculation["BASELINE"] = "BASELINE";
})(EnergyBasisCalculation || (exports.EnergyBasisCalculation = EnergyBasisCalculation = {}));
var EnergyReductionSource;
(function (EnergyReductionSource) {
  EnergyReductionSource["ESTIMATED"] = "ESTIMATED";
  EnergyReductionSource["MODELED"] = "MODELED";
  EnergyReductionSource["DIRECT_MEASUREMENT"] = "DIRECT_MEASUREMENT";
})(EnergyReductionSource || (exports.EnergyReductionSource = EnergyReductionSource = {}));
exports.EnergyTypeReduction = {
  ALL: 'ALL',
  ...EnergyType
};
exports.HeatSteamUtilityType = {
  HEATING: EnergyType.HEATING,
  COOLING: EnergyType.COOLING,
  STEAM: EnergyType.STEAM
};
var TypeOfEmployee;
(function (TypeOfEmployee) {
  TypeOfEmployee["PERMANENT_EMPLOYEES"] = "PERMANENT_EMPLOYEES";
  TypeOfEmployee["TEMPORARY_EMPLOYEES"] = "TEMPORARY_EMPLOYEES";
  TypeOfEmployee["NON_GUARANTEED_HOURS_EMPLOYEES"] = "NON_GUARANTEED_HOURS_EMPLOYEES";
  TypeOfEmployee["FULL_TIME"] = "FULL_TIME";
  TypeOfEmployee["PART_TIME"] = "PART_TIME";
})(TypeOfEmployee || (exports.TypeOfEmployee = TypeOfEmployee = {}));
var TypeOfWork;
(function (TypeOfWork) {
  TypeOfWork["ON_SITE_LABOR"] = "ON_SITE_LABOR";
  TypeOfWork["MANUFACTURING_SUPPORT"] = "MANUFACTURING_SUPPORT";
  TypeOfWork["OPERATIONAL_SUPPORT"] = "OPERATIONAL_SUPPORT";
  TypeOfWork["SUPPLY_CHAIN_SERVICES"] = "SUPPLY_CHAIN_SERVICES";
  TypeOfWork["CLIENT_SITE_WORK"] = "CLIENT_SITE_WORK";
  TypeOfWork["MAINTENANCE_SERVICES"] = "MAINTENANCE_SERVICES";
  TypeOfWork["PRODUCT_DEVELOPMENT"] = "PRODUCT_DEVELOPMENT";
  TypeOfWork["CONSULTING_SERVICES"] = "CONSULTING_SERVICES";
  TypeOfWork["PROJECT_BASED_WORK"] = "PROJECT_BASED_WORK";
  TypeOfWork["REMOTE_SUPPORT_SERVICES"] = "REMOTE_SUPPORT_SERVICES";
  TypeOfWork["OTHER"] = "OTHER";
})(TypeOfWork || (exports.TypeOfWork = TypeOfWork = {}));
var ThirdPartyDescription;
(function (ThirdPartyDescription) {
  ThirdPartyDescription["CONTRACTOR"] = "CONTRACTOR";
  ThirdPartyDescription["EMPLOYMENT_AGENCY"] = "EMPLOYMENT_AGENCY";
  ThirdPartyDescription["VENDOR"] = "VENDOR";
  ThirdPartyDescription["FREELANCER"] = "FREELANCER";
  ThirdPartyDescription["CONSULTANT"] = "CONSULTANT";
  ThirdPartyDescription["LABOR_HIRE"] = "LABOR_HIRE";
  ThirdPartyDescription["NA"] = "NA";
})(ThirdPartyDescription || (exports.ThirdPartyDescription = ThirdPartyDescription = {}));
var ContractualRelationship;
(function (ContractualRelationship) {
  ContractualRelationship["INDIRECT"] = "INDIRECT";
  ContractualRelationship["DIRECT"] = "DIRECT";
})(ContractualRelationship || (exports.ContractualRelationship = ContractualRelationship = {}));
var TypeOfWorker;
(function (TypeOfWorker) {
  TypeOfWorker["AGENCY_WORKER"] = "AGENCY_WORKER";
  TypeOfWorker["APPRENTICE"] = "APPRENTICE";
  TypeOfWorker["CONTRACTOR"] = "CONTRACTOR";
  TypeOfWorker["INTERN"] = "INTERN";
  TypeOfWorker["SELF_EMPLOYED"] = "SELF_EMPLOYED";
})(TypeOfWorker || (exports.TypeOfWorker = TypeOfWorker = {}));
exports.CommitteeType = {
  AUDIT_COMMITTEE: GovernanceBodyCategories.AUDIT_COMMITTEE,
  RISK_MANAGEMENT_COMMITTEE: GovernanceBodyCategories.RISK_MANAGEMENT_COMMITTEE,
  COMPENSATION_COMMITTEE: GovernanceBodyCategories.COMPENSATION_COMMITTEE,
  NOMINATIONS_AND_GOVERNANCE_COMMITTEE: 'NOMINATIONS_AND_GOVERNANCE_COMMITTEE',
  ETHICS_AND_COMPLIANCE_COMMITTEE: 'ETHICS_AND_COMPLIANCE_COMMITTEE',
  SUSTAINABILITY_AND_CORPORATE_RESPONSIBILITY_COMMITTEE: 'SUSTAINABILITY_AND_CORPORATE_RESPONSIBILITY_COMMITTEE',
  FINANCE_COMMITTEE: 'FINANCE_COMMITTEE',
  RESEARCH_AND_DEVELOPMENT_COMMITTEE: 'RESEARCH_AND_DEVELOPMENT_COMMITTEE',
  EXECUTIVE_COMMITTEE: 'EXECUTIVE_COMMITTEE',
  INVESTMENT_COMMITTEE: 'INVESTMENT_COMMITTEE',
  TECHNOLOGY_COMMITTEE: 'TECHNOLOGY_COMMITTEE',
  HUMAN_RESOURCES_COMMITTEE: 'HUMAN_RESOURCES_COMMITTEE',
  PUBLIC_POLICY_AND_GOVERNMENT_AFFAIRS_COMMITTEE: 'PUBLIC_POLICY_AND_GOVERNMENT_AFFAIRS_COMMITTEE',
  HEALTH_SAFETY_AND_ENVIRONMENT_COMMITTEE: 'HEALTH_SAFETY_AND_ENVIRONMENT_COMMITTEE',
  STRATEGIC_PLANNING_COMMITTEE: 'STRATEGIC_PLANNING_COMMITTEE',
  INNOVATION_COMMITTEE: 'INNOVATION_COMMITTEE',
  OTHER: 'OTHER'
};
var UnderRepresentedGroupsDescription;
(function (UnderRepresentedGroupsDescription) {
  UnderRepresentedGroupsDescription["WOMEN"] = "WOMEN";
  UnderRepresentedGroupsDescription["ETHNIC_MINORITIES"] = "ETHNIC_MINORITIES";
  UnderRepresentedGroupsDescription["INDIGENOUS_PEOPLES"] = "INDIGENOUS_PEOPLES";
  UnderRepresentedGroupsDescription["LGBTQ"] = "LGBTQ";
  UnderRepresentedGroupsDescription["PERSONS_WITH_DISABILITIES"] = "PERSONS_WITH_DISABILITIES";
  UnderRepresentedGroupsDescription["VETERANS"] = "VETERANS";
  UnderRepresentedGroupsDescription["YOUNG_PERSONS"] = "YOUNG_PERSONS";
  UnderRepresentedGroupsDescription["SENIOR_CITIZENS"] = "SENIOR_CITIZENS";
  UnderRepresentedGroupsDescription["SOCIOECONOMICALLY_DISADVANTAGED_INDIVIDUALS"] = "SOCIOECONOMICALLY_DISADVANTAGED_INDIVIDUALS";
  UnderRepresentedGroupsDescription["PERSONS_FROM_RURAL_AREAS"] = "PERSONS_FROM_RURAL_AREAS";
  UnderRepresentedGroupsDescription["OTHERS"] = "OTHERS";
})(UnderRepresentedGroupsDescription || (exports.UnderRepresentedGroupsDescription = UnderRepresentedGroupsDescription = {}));
var Competency;
(function (Competency) {
  Competency["STRATEGIC_PLANNING"] = "STRATEGIC_PLANNING";
  Competency["FINANCIAL_OVERSIGHT"] = "FINANCIAL_OVERSIGHT";
  Competency["RISK_MANAGEMENT"] = "RISK_MANAGEMENT";
  Competency["ENVIRONMENTAL_SUSTAINABILITY"] = "ENVIRONMENTAL_SUSTAINABILITY";
  Competency["CORPORATE_GOVERNANCE"] = "CORPORATE_GOVERNANCE";
  Competency["LEGAL_COMPLIANCE"] = "LEGAL_COMPLIANCE";
  Competency["HUMAN_RESOURCES"] = "HUMAN_RESOURCES";
  Competency["TECHNOLOGY_AND_INNOVATION"] = "TECHNOLOGY_AND_INNOVATION";
  Competency["MARKETING_AND_COMMUNICATIONS"] = "MARKETING_AND_COMMUNICATIONS";
  Competency["INTERNATIONAL_BUSINESS"] = "INTERNATIONAL_BUSINESS";
  Competency["PUBLIC_POLICY"] = "PUBLIC_POLICY";
  Competency["COMMUNITY_ENGAGEMENT"] = "COMMUNITY_ENGAGEMENT";
  Competency["SUPPLY_CHAIN_MANAGEMENT"] = "SUPPLY_CHAIN_MANAGEMENT";
  Competency["HEALTH_AND_SAFETY"] = "HEALTH_AND_SAFETY";
  Competency["CRISIS_MANAGEMENT"] = "CRISIS_MANAGEMENT";
  Competency["RESEARCH_AND_DEVELOPMENT"] = "RESEARCH_AND_DEVELOPMENT";
})(Competency || (exports.Competency = Competency = {}));
var Benefits;
(function (Benefits) {
  Benefits["LIFE_INSURANCE"] = "LIFE_INSURANCE";
  Benefits["HEALTH_CARE"] = "HEALTH_CARE";
  Benefits["DISABILITY_AND_INVALIDITY_COVERAGE"] = "DISABILITY AND INVALIDITY COVERAGE";
  Benefits["PARENTAL_LEAVE"] = "PARENTAL_LEAVE";
  Benefits["RETIREMENT_PROVISION"] = "RETIREMENT_PROVISION";
  Benefits["STOCK_OWNERSHIP"] = "STOCK_OWNERSHIP";
  Benefits["OTHERS"] = "OTHERS";
})(Benefits || (exports.Benefits = Benefits = {}));
var WorkerCategory;
(function (WorkerCategory) {
  WorkerCategory["EMPLOYEES"] = "EMPLOYEES";
  WorkerCategory["OTHER_WORKERS"] = "OTHER_WORKERS";
})(WorkerCategory || (exports.WorkerCategory = WorkerCategory = {}));
var TypeOfLegalAction;
(function (TypeOfLegalAction) {
  TypeOfLegalAction["ANTI_COMPETITIVE_BEHAVIOR"] = "ANTI_COMPETITIVE_BEHAVIOR";
  TypeOfLegalAction["VIOLATIONS_OF_ANTI_TRUST"] = "VIOLATIONS_OF_ANTI_TRUST";
  TypeOfLegalAction["MONOPOLY_LEGISLATION"] = "MONOPOLY_LEGISLATION";
})(TypeOfLegalAction || (exports.TypeOfLegalAction = TypeOfLegalAction = {}));
var TypeOfBenefitPlan;
(function (TypeOfBenefitPlan) {
  TypeOfBenefitPlan["DEFINED_BENEFIT"] = "DEFINED_BENEFIT";
  TypeOfBenefitPlan["DEFINED_CONTRIBUTION_PLAN"] = "DEFINED_CONTRIBUTION_PLAN";
  TypeOfBenefitPlan["OTHER_RETIREMENT_PLANS"] = "OTHER_RETIREMENT_PLANS";
})(TypeOfBenefitPlan || (exports.TypeOfBenefitPlan = TypeOfBenefitPlan = {}));
var PlanCoverage;
(function (PlanCoverage) {
  PlanCoverage["ALL_EMPLOYEES"] = "ALL_EMPLOYEES";
  PlanCoverage["FULL_TIME_EMPLOYEES"] = "FULL_TIME_EMPLOYEES";
  PlanCoverage["EXECUTIVES"] = "EXECUTIVES";
})(PlanCoverage || (exports.PlanCoverage = PlanCoverage = {}));
var FundingStatus;
(function (FundingStatus) {
  FundingStatus["FULLY_FUNDED"] = "FULLY_FUNDED";
  FundingStatus["UNDERFUNDED"] = "UNDERFUNDED";
  FundingStatus["OVERFUNDED"] = "OVERFUNDED";
})(FundingStatus || (exports.FundingStatus = FundingStatus = {}));
var ParticipationLevel;
(function (ParticipationLevel) {
  ParticipationLevel["MANDATORY"] = "MANDATORY";
  ParticipationLevel["VOLUNTARY"] = "VOLUNTARY";
  ParticipationLevel["REGIONAL"] = "REGIONAL";
  ParticipationLevel["COUNTRY_BASED"] = "COUNTRY_BASED";
})(ParticipationLevel || (exports.ParticipationLevel = ParticipationLevel = {}));
var TypeOfAssistance;
(function (TypeOfAssistance) {
  TypeOfAssistance["TAX_RELIEF"] = "TAX_RELIEF";
  TypeOfAssistance["TAX_CREDIT"] = "TAX_CREDIT";
  TypeOfAssistance["SUBSIDY"] = "SUBSIDY";
  TypeOfAssistance["INVESTMENT_GRANT"] = "INVESTMENT_GRANT";
  TypeOfAssistance["R_AND_D_GRANT"] = "R_AND_D_GRANT";
  TypeOfAssistance["AWARD"] = "AWARD";
  TypeOfAssistance["ROYALTY_HOLIDAY"] = "ROYALTY_HOLIDAY";
  TypeOfAssistance["ECA_ASSISTANCE"] = "ECA_ASSISTANCE";
  TypeOfAssistance["FINANCIAL_INCENTIVE"] = "FINANCIAL_INCENTIVE";
  TypeOfAssistance["OTHER_FINANCIAL_BENEFITS"] = "OTHER_FINANCIAL_BENEFITS";
})(TypeOfAssistance || (exports.TypeOfAssistance = TypeOfAssistance = {}));
var Duration;
(function (Duration) {
  Duration["ONE_TIME"] = "ONE_TIME";
  Duration["ANNUAL"] = "ANNUAL";
  Duration["MULTI_YEAR"] = "MULTI_YEAR";
})(Duration || (exports.Duration = Duration = {}));
var RiskOpportunityTypes;
(function (RiskOpportunityTypes) {
  RiskOpportunityTypes["PHYSICAL"] = "PHYSICAL";
  RiskOpportunityTypes["REGULATORY"] = "REGULATORY";
  RiskOpportunityTypes["MARKET"] = "MARKET";
  RiskOpportunityTypes["REPUTATIONAL"] = "REPUTATIONAL";
})(RiskOpportunityTypes || (exports.RiskOpportunityTypes = RiskOpportunityTypes = {}));
var TimeFrame;
(function (TimeFrame) {
  TimeFrame["SHORT_TERM"] = "SHORT_TERM";
  TimeFrame["MEDIUM_TERM"] = "MEDIUM_TERM";
  TimeFrame["LONG_TERM"] = "LONG_TERM";
})(TimeFrame || (exports.TimeFrame = TimeFrame = {}));
var Impacts;
(function (Impacts) {
  Impacts["INDIRECT"] = "INDIRECT";
  Impacts["DIRECT"] = "DIRECT";
})(Impacts || (exports.Impacts = Impacts = {}));
var Likelihoods;
(function (Likelihoods) {
  Likelihoods["HIGH"] = "HIGH";
  Likelihoods["MEDIUM"] = "MEDIUM";
  Likelihoods["LOW"] = "LOW";
})(Likelihoods || (exports.Likelihoods = Likelihoods = {}));
var ImpactMagnitude;
(function (ImpactMagnitude) {
  ImpactMagnitude["MAJOR"] = "MAJOR";
  ImpactMagnitude["MODERATE"] = "MODERATE";
  ImpactMagnitude["MINOR"] = "MINOR";
})(ImpactMagnitude || (exports.ImpactMagnitude = ImpactMagnitude = {}));
