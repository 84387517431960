import { Injectable } from '@angular/core';
import {
  requestLoading,
  RequestLoadingType,
} from '@ieCore/helpers/request-loading.helper';
import { TopicRepository } from '@ieCore/repositories/topic.repository';
import { CategoryResponseDto } from 'shared-general-libs/dto/category';
import { SaveTopicDto } from 'shared-general-libs/dto/topic';
import { TopicNameMapping } from '@ieShared/interfaces/topic.interface';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TopicService {
  topicList: TopicNameMapping[] = [];

  constructor(private topicRepo: TopicRepository) {}

  async storeTopics() {
    const topics = await firstValueFrom(this.getTopics().response);

    this.topicList = topics.map((i) => ({
      name: i.name,
      hint: i.hint,
    }));
  }

  getTopics(): RequestLoadingType<CategoryResponseDto[]> {
    return requestLoading(this.topicRepo.getTopics());
  }

  getAdminTopics(orgId: string): RequestLoadingType<CategoryResponseDto[]> {
    return requestLoading(this.topicRepo.getAdminTopics(orgId));
  }

  getTopicsTree(name: string): RequestLoadingType<CategoryResponseDto> {
    return requestLoading(this.topicRepo.getTopicsTree(name));
  }

  save(data: SaveTopicDto[]): RequestLoadingType<void> {
    return requestLoading(this.topicRepo.saveTopics(data));
  }

  saveAdminTopics(
    orgId: string,
    data: SaveTopicDto[],
  ): RequestLoadingType<void> {
    return requestLoading(this.topicRepo.saveAdminTopics(orgId, data));
  }

  getEnabledTopics(topics: CategoryResponseDto[]): SaveTopicDto[] {
    const data: SaveTopicDto[] = [];
    this.getEnabledTopicsFromTree(topics, data);
    return data;
  }

  mergeEnabledTopics(
    oldTopics: SaveTopicDto[],
    newTopics: SaveTopicDto[],
  ): SaveTopicDto[] {
    const data: SaveTopicDto[] = [...newTopics];

    for (const oTopic of oldTopics) {
      const nTopic = newTopics.find(
        (topic: SaveTopicDto) => topic.name === oTopic.name,
      );

      if (!nTopic) {
        data.push({ name: oTopic.name, enabled: false });
      }
    }

    return data;
  }

  getEnabledTopicsFromTree(
    topics: CategoryResponseDto[],
    data: SaveTopicDto[],
  ) {
    topics.forEach((topic: CategoryResponseDto) => {
      if (topic.enabled) {
        data.push({
          name: topic.name,
          enabled: topic.enabled,
        });
      }

      if (topic.children) {
        this.getEnabledTopicsFromTree(topic.children, data);
      }
    });
  }
}
