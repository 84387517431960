import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormError } from '@ieCore/enums/form-error';
import { TranslateModule } from '@ngx-translate/core';
import { IeIconsComponent } from '@ieShared/components/ie-icons/ie-icons.component';
import { JsonPipe } from '@angular/common';
import { TranslateWithFallbackPipe } from '@ieShared/pipes/translate-with-fallback.pipe';

@Component({
  selector: 'app-ie-form-error',
  standalone: true,
  imports: [
    TranslateModule,
    IeIconsComponent,
    JsonPipe,
    TranslateWithFallbackPipe,
  ],
  templateUrl: './ie-form-error.component.html',
  styleUrl: './ie-form-error.component.scss',
})
export class IeFormErrorComponent {
  @Input() field!: AbstractControl<unknown>;

  formErrors = FormError;
}
