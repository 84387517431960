"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImportanceType = void 0;
var ImportanceType;
(function (ImportanceType) {
  ImportanceType["BUSINESS"] = "BUSINESS";
  ImportanceType["STAKEHOLDERS"] = "STAKEHOLDERS";
})(ImportanceType || (exports.ImportanceType = ImportanceType = {}));
