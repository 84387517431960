"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formSelectFiltersMapping = exports.FormSelectFiltersFunctions = void 0;
const type_1 = require("../type");
function filterRefrigerantEquipmentOptions(options, data) {
  const {
    emissionType
  } = data;
  return options.filter(option => {
    switch (emissionType) {
      case type_1.EmissionTypeRefrigerant.ASSEMBLY_EMISSIONS:
        return ['TRANSPORT_REFRIGERATION'].includes(option.key);
      case type_1.EmissionTypeRefrigerant.OPERATION_EMISSIONS:
        return ['c', 'e'].includes(option.key);
      default:
        return true;
    }
  });
}
var FormSelectFiltersFunctions;
(function (FormSelectFiltersFunctions) {
  FormSelectFiltersFunctions["REFRIGERANT_EQUIPMENT_FILTER"] = "REFRIGERANT_EQUIPMENT_FILTER";
})(FormSelectFiltersFunctions || (exports.FormSelectFiltersFunctions = FormSelectFiltersFunctions = {}));
exports.formSelectFiltersMapping = {
  REFRIGERANT_EQUIPMENT_FILTER: filterRefrigerantEquipmentOptions
};
