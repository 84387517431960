"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContactType = void 0;
var ContactType;
(function (ContactType) {
  ContactType["INTERNAL"] = "INTERNAL";
  ContactType["EXTERNAL"] = "EXTERNAL";
})(ContactType || (exports.ContactType = ContactType = {}));
