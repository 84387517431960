"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MicroServiceUrls = exports.FormDefaultValueTypes = exports.CalculationResultType = exports.FormDependsOnRequestTypes = exports.FormFieldType = exports.FormValidatorsEnum = exports.FormNumberCompareEnum = exports.FormDateCompareEnum = exports.FormRegexpNames = exports.FormOnChangeFunctions = exports.FormNames = void 0;
var FormNames;
(function (FormNames) {
  FormNames["EMISSION_FACTOR_LIBRARY"] = "EMISSION_FACTOR_LIBRARY";
  FormNames["FACILITY_FORM"] = "FACILITY_FORM";
  FormNames["EDIT_FACILITY_FORM"] = "EDIT_FACILITY_FORM";
  FormNames["EQUIPMENT_FORM"] = "EQUIPMENT_FORM";
  FormNames["MANAGE_PRODUCTS_FORM"] = "MANAGE_PRODUCTS_FORM";
  FormNames["ATTACH_PRODUCTS_FORM"] = "ATTACH_PRODUCTS_FORM";
  FormNames["ATTACH_PARTNER_PRODUCTS_FORM"] = "ATTACH_PARTNER_PRODUCTS_FORM";
  FormNames["UPLOAD_PRODUCT_DOCUMENT_FORM"] = "UPLOAD_PRODUCT_DOCUMENT_FORM";
  FormNames["ADD_PARTNER_FORM"] = "ADD_PARTNER_FORM";
  FormNames["EDIT_PARTNER_FORM"] = "EDIT_PARTNER_FORM";
  FormNames["EDIT_SELF_PARTNER_FORM"] = "EDIT_SELF_PARTNER_FORM";
  FormNames["PARTNER_PROFILE_EDIT_FORM"] = "PARTNER_PROFILE_EDIT_FORM";
  FormNames["ADD_USER_FORM"] = "ADD_USER_FORM";
  FormNames["EDIT_USER_FORM"] = "EDIT_USER_FORM";
  FormNames["ADD_ADMIN_USER_FORM"] = "ADD_ADMIN_USER_FORM";
  FormNames["EDIT_ADMIN_USER_FORM"] = "EDIT_ADMIN_USER_FORM";
  FormNames["COMPANY_GENERAL_FORM"] = "COMPANY_GENERAL_FORM";
  FormNames["COMPANY_ADDRESS_FORM"] = "COMPANY_ADDRESS_FORM";
  FormNames["CREATE_REQUEST_FORM"] = "CREATE_REQUEST_FORM";
  FormNames["ENERGY_EMISSION_FACTOR_LIBRARY"] = "ENERGY_EMISSION_FACTOR_LIBRARY";
  FormNames["ADD_MANAGE_PARTNER_FORM"] = "ADD_MANAGE_PARTNER_FORM";
  FormNames["EDIT_MANAGE_PARTNER_FORM"] = "EDIT_MANAGE_PARTNER_FORM";
  FormNames["ADD_ORG_FORM"] = "ADD_ORG_FORM";
  FormNames["ADD_CONTACT_FORM"] = "ADD_CONTACT_FORM";
  FormNames["EDIT_CONTACT_FORM"] = "EDIT_CONTACT_FORM";
})(FormNames || (exports.FormNames = FormNames = {}));
var FormOnChangeFunctions;
(function (FormOnChangeFunctions) {
  FormOnChangeFunctions["DIVIDE"] = "DIVIDE";
  FormOnChangeFunctions["PERCENT"] = "PERCENT";
  FormOnChangeFunctions["CNR_END_DATE"] = "CNR_END_DATE";
  FormOnChangeFunctions["ECONOMIC_VALUE_RETAINED"] = "ECONOMIC_VALUE_RETAINED";
})(FormOnChangeFunctions || (exports.FormOnChangeFunctions = FormOnChangeFunctions = {}));
var FormRegexpNames;
(function (FormRegexpNames) {
  FormRegexpNames["ONLY_NUMBERS_ONE_HYPHEN_REGEXP"] = "ONLY_NUMBERS_ONE_HYPHEN_REGEXP";
  FormRegexpNames["ONLY_NUMBERS_AND_LETTERS"] = "ONLY_NUMBERS_AND_LETTERS";
  FormRegexpNames["ONLY_NUMBERS_REGEXP"] = "ONLY_NUMBERS_REGEXP";
  FormRegexpNames["WEBSITE_URL_REGEXP"] = "WEBSITE_URL_REGEXP";
})(FormRegexpNames || (exports.FormRegexpNames = FormRegexpNames = {}));
var FormDateCompareEnum;
(function (FormDateCompareEnum) {
  FormDateCompareEnum["AFTER"] = "AFTER";
  FormDateCompareEnum["BEFORE"] = "BEFORE";
})(FormDateCompareEnum || (exports.FormDateCompareEnum = FormDateCompareEnum = {}));
var FormNumberCompareEnum;
(function (FormNumberCompareEnum) {
  FormNumberCompareEnum["SMALLER_THAN_OR_EQUAL"] = "SMALLER_THAN_OR_EQUAL";
  FormNumberCompareEnum["BIGGER_THAN_OR_EQUAL"] = "BIGGER_THAN_OR_EQUAL";
})(FormNumberCompareEnum || (exports.FormNumberCompareEnum = FormNumberCompareEnum = {}));
var FormValidatorsEnum;
(function (FormValidatorsEnum) {
  FormValidatorsEnum["REQUIRED"] = "REQUIRED";
  FormValidatorsEnum["EMAIL"] = "EMAIL";
  FormValidatorsEnum["LENGTH"] = "LENGTH";
  FormValidatorsEnum["MIN_MAX"] = "MIN_MAX";
  FormValidatorsEnum["PATTERN"] = "PATTERN";
  FormValidatorsEnum["PASSWORD"] = "PASSWORD";
  FormValidatorsEnum["CONFIRM_PASSWORD"] = "CONFIRM_PASSWORD";
  FormValidatorsEnum["INT"] = "INT";
  FormValidatorsEnum["COMPARE_DATE"] = "COMPARE_DATE";
  FormValidatorsEnum["COMPARE_NUMBER"] = "COMPARE_NUMBER";
})(FormValidatorsEnum || (exports.FormValidatorsEnum = FormValidatorsEnum = {}));
var FormFieldType;
(function (FormFieldType) {
  FormFieldType["TEXT"] = "text";
  FormFieldType["TEXTAREA"] = "textarea";
  FormFieldType["NUMBER"] = "number";
  FormFieldType["DATE"] = "date";
  FormFieldType["YEAR"] = "year";
  FormFieldType["MONTH"] = "month";
  FormFieldType["SELECT"] = "select";
  FormFieldType["MULTISELECT"] = "multiselect";
  FormFieldType["RADIO"] = "radio";
  FormFieldType["TAB"] = "tab";
  FormFieldType["TOGGLE"] = "toggle";
  FormFieldType["CALCULATE"] = "calculate";
  FormFieldType["CHECKBOX"] = "checkbox";
  FormFieldType["PASSWORD"] = "password";
  FormFieldType["HIDDEN"] = "hidden";
  FormFieldType["MEDIA"] = "media";
  FormFieldType["AVATAR"] = "avatar";
  FormFieldType["MULTI_MEDIA"] = "multiselect-media";
  FormFieldType["PHONE"] = "phone";
})(FormFieldType || (exports.FormFieldType = FormFieldType = {}));
var FormDependsOnRequestTypes;
(function (FormDependsOnRequestTypes) {
  FormDependsOnRequestTypes["GET"] = "GET";
  FormDependsOnRequestTypes["POST"] = "POST";
  FormDependsOnRequestTypes["PUT"] = "PUT";
  FormDependsOnRequestTypes["PATCH"] = "PATCH";
})(FormDependsOnRequestTypes || (exports.FormDependsOnRequestTypes = FormDependsOnRequestTypes = {}));
var CalculationResultType;
(function (CalculationResultType) {
  CalculationResultType["FILL"] = "FILL";
  CalculationResultType["SHOW"] = "SHOW";
})(CalculationResultType || (exports.CalculationResultType = CalculationResultType = {}));
var FormDefaultValueTypes;
(function (FormDefaultValueTypes) {
  FormDefaultValueTypes["CONST"] = "CONST";
  FormDefaultValueTypes["FORM_STORE"] = "FORM_STORE";
  FormDefaultValueTypes["REQUEST"] = "REQUEST";
})(FormDefaultValueTypes || (exports.FormDefaultValueTypes = FormDefaultValueTypes = {}));
var MicroServiceUrls;
(function (MicroServiceUrls) {
  MicroServiceUrls["FORM_SERVICE_URL"] = "{FORM_SERVICE_URL}";
  MicroServiceUrls["AUTHORIZATION_SERVICE_URL"] = "{AUTHORIZATION_SERVICE_URL}";
  MicroServiceUrls["FACILITY_SERVICE_URL"] = "{FACILITY_SERVICE_URL}";
  MicroServiceUrls["USER_SERVICE_URL"] = "{USER_SERVICE_URL}";
  MicroServiceUrls["TOPIC_SERVICE_URL"] = "{TOPIC_SERVICE_URL}";
  MicroServiceUrls["EMISSION_FACTOR_SERVICE_URL"] = "{EMISSION_FACTOR_SERVICE_URL}";
  MicroServiceUrls["CATEGORY_SERVICE_URL"] = "{CATEGORY_SERVICE_URL}";
  MicroServiceUrls["EQUIPMENT_SERVICE_URL"] = "{EQUIPMENT_SERVICE_URL}";
  MicroServiceUrls["PARTNER_SERVICE_URL"] = "{PARTNER_SERVICE_URL}";
  MicroServiceUrls["ORGANIZATION_SERVICE_URL"] = "{ORGANIZATION_SERVICE_URL}";
  MicroServiceUrls["COLLECT_SERVICE_URL"] = "{COLLECT_SERVICE_URL}";
  MicroServiceUrls["CALCULATION_SERVICE_URL"] = "{CALCULATION_SERVICE_URL}";
  MicroServiceUrls["PRODUCT_SERVICE_URL"] = "{PRODUCT_SERVICE_URL}";
})(MicroServiceUrls || (exports.MicroServiceUrls = MicroServiceUrls = {}));
