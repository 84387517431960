"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRecoveryOperation = exports.getDisposalOperation = exports.getWasteTypeOptions = exports.getWasteSourceOptions = exports.getWasteCompositionOptions = exports.getWasteMaterialOptions = exports.getMaritalStatusOptions = exports.getReligionOptions = exports.getSocioeconomicStatusOptions = exports.getLGBTQIdentityOptions = exports.getVeteranStatusOptions = exports.getIndigenousStatusOptions = exports.getNationalityOptions = exports.getEducationalLevelOptions = exports.getDisabilityOptions = exports.getEthnicityOptions = exports.getDiversityIndicatorOptions = exports.getEmployeeCategoriesOptions = exports.getGovernanceBodyCategoryOptions = exports.getEmployeeFunctionOptions = exports.getEmployeeLevelOptions = exports.getInjurySeverityOptions = exports.getInjuryOptions = exports.getWorkerTypeOptions = exports.getAuditTypeOptions = exports.getWorkerCategoryOptions = exports.getStakeholderTypeOptions = exports.getStakeholderCategoryOptions = exports.getYesNoNA = exports.getPositiveNegative = exports.getCurrencyOptions = exports.getAgeGroupOptions = exports.getRegionOptions = exports.getGenderOptions = exports.getEnergyDensityOutUnitOption = exports.getEnergyDensityInputUnitOption = exports.getEnergyInputUnitOption = exports.getEnergyOutputUnitOption = exports.getPartnerScopeOptions = exports.getPartnerTypeOptions = exports.getPartnerClassificationOptions = exports.getProductCategoryOptions = exports.getPartnerCategoryOptions = exports.getReportingPeriodOptionsList = exports.getContractTypes = exports.getUserScopes = exports.getOrgTypeOptions = exports.booleanOptions = exports.createOptionsFromValues = exports.createOptions = void 0;
exports.getInvestmentCategory = exports.impactMagnitudeOptions = exports.likelihoodOptions = exports.impactOptions = exports.timeFrameOptions = exports.riskOpportunityTypesOptions = exports.durationOptions = exports.typeOfAssistanceOptions = exports.participationLevelOptions = exports.fundingStatusOptions = exports.planCoverageOptions = exports.typeOfBenefitPlanOptions = exports.typeOfLegalActionOptions = exports.workCategoryOptions = exports.materialCategoryOptions = exports.massAndVolumeUnitsOptions = exports.measurementMethodOptions = exports.sourceOfMaterialOptions = exports.renewableStatusOptions = exports.treatmentLevelOptions = exports.waterCategoryOptions = exports.waterAreaOptions = exports.waterSourceOptions = exports.competencyOptions = exports.underRepresentedGroupsDescriptionOptions = exports.committeeTypeOptions = exports.typeofWorker = exports.thirdPartyDescription = exports.contractualRelationshipOptions = exports.typeOfWorkOptions = exports.typeOfEmployeeOptions = exports.getTransitionAssistanceProgramOptions = exports.getTrainingProgramOptions = exports.getTrainingEmployeeCategoryOptions = exports.getEnergyEnergyFuelTypeOptions = exports.getEnergyActivityCategoriesOptions = exports.getEnergyActivityOptions = exports.getEnergyOperationOptions = exports.getEnergyReductionSourceOptions = exports.getBasisForCalculationOptions = exports.getBusinessMetricOptions = exports.getAllEnergyTypeOptions = exports.getEnergyTypeOptions = exports.getBenefitsOptions = exports.heatSteamUtilityTypeOptions = exports.processEmissionsUnitOption = exports.purchasedEleCalculationApproachOptions = exports.refrigerantOption = exports.activityTypeMobileOption = exports.getSiteLocation = void 0;
exports.getTypeOfOperation = exports.getImpactType = exports.getBeneficiaries = exports.getInvestmentTypes = exports.getProjectStatus = void 0;
const type_1 = require("../type");
const period_1 = require("../enum/period");
const stakeholder_category_1 = require("../enum/stakeholder-category");
const enum_1 = require("../enum");
const stakeholder_type_1 = require("../enum/stakeholder-type");
const activity_1 = require("../enum/activity");
const partner_1 = require("../type/partner");
const emissions_1 = require("../type/emissions");
const training_program_type_1 = require("../enum/training-program-type");
const transition_assistance_program_type_1 = require("../enum/transition-assistance-program-type");
const training_employee_category_1 = require("../enum/training-employee-category");
const water_source_1 = require("../enum/water-source");
const water_area_1 = require("../enum/water-area");
const water_category_1 = require("../enum/water-category");
const treatment_level_1 = require("../enum/treatment-level");
const renewable_status_1 = require("../enum/renewable-status");
const source_of_material_1 = require("../enum/source-of-material");
const measurement_method_1 = require("../enum/measurement-method");
const material_category_1 = require("../enum/material-category");
const createOptions = (enumTypes, translationPath) => {
  return Object.keys(enumTypes).map(item => ({
    key: item,
    label: translationPath ? `${translationPath}.${item}` : item
  }));
};
exports.createOptions = createOptions;
const createOptionsFromValues = (enumTypes, translationPath) => {
  return Object.values(enumTypes).map(item => ({
    key: item,
    label: `${translationPath}.${item}`
  }));
};
exports.createOptionsFromValues = createOptionsFromValues;
exports.booleanOptions = [{
  key: 'yes',
  label: 'YES'
}, {
  key: 'no',
  label: 'NO'
}];
const getOrgTypeOptions = () => (0, exports.createOptions)(type_1.OrgType, 'ORG.ORG_TYPE');
exports.getOrgTypeOptions = getOrgTypeOptions;
const getUserScopes = () => (0, exports.createOptions)(type_1.UserScope, 'USER.USER_SCOPES');
exports.getUserScopes = getUserScopes;
const getContractTypes = () => (0, exports.createOptions)(type_1.ContractType, 'USER.CONTRACT_TYPES');
exports.getContractTypes = getContractTypes;
const getReportingPeriodOptionsList = () => (0, exports.createOptions)(period_1.ReportingPeriod, 'COLLECT.REPORTING_PERIODS');
exports.getReportingPeriodOptionsList = getReportingPeriodOptionsList;
const getPartnerCategoryOptions = () => (0, exports.createOptions)(partner_1.PartnerCategory, 'PARTNER.PARTNER_CATEGORY');
exports.getPartnerCategoryOptions = getPartnerCategoryOptions;
const getProductCategoryOptions = () => (0, exports.createOptions)(type_1.ProductCategory, 'PRODUCTS.CATEGORIES');
exports.getProductCategoryOptions = getProductCategoryOptions;
const getPartnerClassificationOptions = () => (0, exports.createOptions)(partner_1.Classification, 'PARTNER.PARTNER_CLASSIFICATION');
exports.getPartnerClassificationOptions = getPartnerClassificationOptions;
const getPartnerTypeOptions = () => (0, exports.createOptions)(partner_1.PartnerType, 'PARTNER.PARTNER_TYPE');
exports.getPartnerTypeOptions = getPartnerTypeOptions;
const getPartnerScopeOptions = () => (0, exports.createOptions)(partner_1.PartnerScope, 'PARTNER.PARTNER_SCOPE');
exports.getPartnerScopeOptions = getPartnerScopeOptions;
const getEnergyOutputUnitOption = () => (0, exports.createOptionsFromValues)(type_1.EnergyOutput, 'UNITS_BE');
exports.getEnergyOutputUnitOption = getEnergyOutputUnitOption;
const getEnergyInputUnitOption = () => (0, exports.createOptionsFromValues)(type_1.SolidUnit, 'UNITS_BE');
exports.getEnergyInputUnitOption = getEnergyInputUnitOption;
const getEnergyDensityInputUnitOption = () => (0, exports.createOptionsFromValues)(type_1.DensityInputUnit, 'UNITS_BE');
exports.getEnergyDensityInputUnitOption = getEnergyDensityInputUnitOption;
const getEnergyDensityOutUnitOption = () => (0, exports.createOptionsFromValues)(type_1.SolidUnit, 'UNITS_BE');
exports.getEnergyDensityOutUnitOption = getEnergyDensityOutUnitOption;
const getGenderOptions = () => (0, exports.createOptions)(activity_1.Genders, 'TOPIC.GENDERS');
exports.getGenderOptions = getGenderOptions;
const getRegionOptions = () => (0, exports.createOptions)(enum_1.RegionEnum, 'TOPIC.REGION');
exports.getRegionOptions = getRegionOptions;
const getAgeGroupOptions = () => (0, exports.createOptions)(activity_1.AgeGroups, 'TOPIC.AGE_GROUPS');
exports.getAgeGroupOptions = getAgeGroupOptions;
const getCurrencyOptions = () => (0, exports.createOptions)(type_1.Currency, 'TOPIC.CURRENCIES');
exports.getCurrencyOptions = getCurrencyOptions;
const getPositiveNegative = () => (0, exports.createOptions)(type_1.PositiveNegative, 'TOPIC.POSITIVE_NEGATIVE');
exports.getPositiveNegative = getPositiveNegative;
const getYesNoNA = () => (0, exports.createOptions)(type_1.YesNoNA, 'TOPIC.YES_NO_NA');
exports.getYesNoNA = getYesNoNA;
const getStakeholderCategoryOptions = () => (0, exports.createOptions)(stakeholder_category_1.StakeholderCategoryEnum, 'TOPICS:ANTI_CORRUPTION.COMMUNICATION_TRAINING_ABOUT_ANTI_CORRUPTION.STAKE_HOLDER_CATEGORIES');
exports.getStakeholderCategoryOptions = getStakeholderCategoryOptions;
const getStakeholderTypeOptions = () => (0, exports.createOptions)(stakeholder_type_1.StakeholderTypeEnum, 'TOPICS:ANTI_CORRUPTION.COMMUNICATION_TRAINING_ABOUT_ANTI_CORRUPTION.STAKE_HOLDER_TYPES');
exports.getStakeholderTypeOptions = getStakeholderTypeOptions;
const getWorkerCategoryOptions = () => (0, exports.createOptions)(activity_1.WorkerCategories, 'TOPICS:EMPLOYEE_HEALTH_AND_SAFETY.WORKER_CATEGORIES');
exports.getWorkerCategoryOptions = getWorkerCategoryOptions;
const getAuditTypeOptions = () => (0, exports.createOptions)(activity_1.AuditTypes, 'TOPICS:EMPLOYEE_HEALTH_AND_SAFETY.AUDIT_TYPES');
exports.getAuditTypeOptions = getAuditTypeOptions;
const getWorkerTypeOptions = () => (0, exports.createOptions)(activity_1.WorkerTypes, 'TOPICS:EMPLOYEE_HEALTH_AND_SAFETY.WORKER_TYPES');
exports.getWorkerTypeOptions = getWorkerTypeOptions;
const getInjuryOptions = () => (0, exports.createOptions)(activity_1.InjuryTypes, 'TOPICS:EMPLOYEE_HEALTH_AND_SAFETY.INJURY_TYPES');
exports.getInjuryOptions = getInjuryOptions;
const getInjurySeverityOptions = () => (0, exports.createOptions)(activity_1.InjurySeverities, 'TOPICS:EMPLOYEE_HEALTH_AND_SAFETY.INJURY_SEVERITY');
exports.getInjurySeverityOptions = getInjurySeverityOptions;
const getEmployeeLevelOptions = () => (0, exports.createOptions)(activity_1.EmployeeLevel, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.EMPLOYEE_LEVEL');
exports.getEmployeeLevelOptions = getEmployeeLevelOptions;
const getEmployeeFunctionOptions = () => (0, exports.createOptions)(activity_1.EmployeeFunction, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.EMPLOYEE_FUNCTION');
exports.getEmployeeFunctionOptions = getEmployeeFunctionOptions;
const getGovernanceBodyCategoryOptions = () => (0, exports.createOptions)(activity_1.GovernanceBodyCategories, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.GOVERNANCE_BODY_CATEGORIES');
exports.getGovernanceBodyCategoryOptions = getGovernanceBodyCategoryOptions;
const getEmployeeCategoriesOptions = () => (0, exports.createOptions)(activity_1.EmployeeCategory, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.EMPLOYEE_CATEGORIES');
exports.getEmployeeCategoriesOptions = getEmployeeCategoriesOptions;
const getDiversityIndicatorOptions = () => (0, exports.createOptions)(activity_1.DiversityIndicators, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.DIVERSITY_INDICATORS');
exports.getDiversityIndicatorOptions = getDiversityIndicatorOptions;
const getEthnicityOptions = () => (0, exports.createOptions)(activity_1.Ethnicity, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.ETHNICITY');
exports.getEthnicityOptions = getEthnicityOptions;
const getDisabilityOptions = () => (0, exports.createOptions)(activity_1.Disability, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.DISABILITY');
exports.getDisabilityOptions = getDisabilityOptions;
const getEducationalLevelOptions = () => (0, exports.createOptions)(activity_1.EducationalLevel, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.EDUCATIONAL_LEVEL');
exports.getEducationalLevelOptions = getEducationalLevelOptions;
const getNationalityOptions = () => (0, exports.createOptions)(activity_1.Nationality, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.NATIONALITY');
exports.getNationalityOptions = getNationalityOptions;
const getIndigenousStatusOptions = () => (0, exports.createOptions)(activity_1.IndigenousStatus, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.INDIGENOUS_STATUS');
exports.getIndigenousStatusOptions = getIndigenousStatusOptions;
const getVeteranStatusOptions = () => (0, exports.createOptions)(activity_1.VeteranStatus, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.VETERAN_STATUS');
exports.getVeteranStatusOptions = getVeteranStatusOptions;
const getLGBTQIdentityOptions = () => (0, exports.createOptions)(activity_1.LGBTQIdentity, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.LGBTQ_IDENTITY');
exports.getLGBTQIdentityOptions = getLGBTQIdentityOptions;
const getSocioeconomicStatusOptions = () => (0, exports.createOptions)(activity_1.SocioeconomicStatus, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.SOCIOECONOMIC_STATUS');
exports.getSocioeconomicStatusOptions = getSocioeconomicStatusOptions;
const getReligionOptions = () => (0, exports.createOptions)(activity_1.Religion, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.RELIGION');
exports.getReligionOptions = getReligionOptions;
const getMaritalStatusOptions = () => (0, exports.createOptions)(activity_1.MaritalStatus, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.MARITAL_STATUS');
exports.getMaritalStatusOptions = getMaritalStatusOptions;
const getWasteMaterialOptions = () => (0, exports.createOptions)(activity_1.WasteMaterial, 'TOPICS:SOLID_WASTE.WASTE_MATERIAL');
exports.getWasteMaterialOptions = getWasteMaterialOptions;
const getWasteCompositionOptions = () => (0, exports.createOptions)(activity_1.WasteComposition, 'TOPICS:SOLID_WASTE.WASTE_COMPOSITION');
exports.getWasteCompositionOptions = getWasteCompositionOptions;
const getWasteSourceOptions = () => (0, exports.createOptions)(activity_1.WasteSource, 'TOPICS:SOLID_WASTE.WASTE_SOURCE');
exports.getWasteSourceOptions = getWasteSourceOptions;
const getWasteTypeOptions = () => (0, exports.createOptions)(activity_1.WasteType, 'TOPICS:SOLID_WASTE.WASTE_TYPE');
exports.getWasteTypeOptions = getWasteTypeOptions;
const getDisposalOperation = () => (0, exports.createOptions)(activity_1.DisposalOperation, 'TOPICS:SOLID_WASTE.DISPOSAL_OPERATION');
exports.getDisposalOperation = getDisposalOperation;
const getRecoveryOperation = () => (0, exports.createOptions)(activity_1.RecoveryOperation, 'TOPICS:SOLID_WASTE.RECOVERY_OPERATION');
exports.getRecoveryOperation = getRecoveryOperation;
const getSiteLocation = () => (0, exports.createOptions)(activity_1.SiteLocation, 'TOPICS:SOLID_WASTE.SITE_LOCATION');
exports.getSiteLocation = getSiteLocation;
const activityTypeMobileOption = () => (0, exports.createOptions)(type_1.ActivityTypeMobile, 'TOPICS:EMISSIONS.MOBILE_COMBUSTION.ACTIVITY_TYPES');
exports.activityTypeMobileOption = activityTypeMobileOption;
const refrigerantOption = () => (0, exports.createOptions)(type_1.EmissionTypeRefrigerant, 'TOPICS:EMISSIONS.REFRIGERANT.EMISSION_TYPES');
exports.refrigerantOption = refrigerantOption;
const purchasedEleCalculationApproachOptions = () => (0, exports.createOptions)(type_1.PurchasedEleCalculation, 'TOPICS:EMISSIONS.PURCHASED_ELECTRICITY.CALCULATION_APPROACHES');
exports.purchasedEleCalculationApproachOptions = purchasedEleCalculationApproachOptions;
const processEmissionsUnitOption = () => (0, exports.createOptionsFromValues)(type_1.SolidUnit, 'UNITS_BE');
exports.processEmissionsUnitOption = processEmissionsUnitOption;
const heatSteamUtilityTypeOptions = () => (0, exports.createOptions)(activity_1.HeatSteamUtilityType, 'TOPICS:EMISSIONS.STEAM_AND_HEAT.UTILITY_TYPES');
exports.heatSteamUtilityTypeOptions = heatSteamUtilityTypeOptions;
const getBenefitsOptions = () => (0, exports.createOptions)(activity_1.Benefits, 'TOPICS:EMPLOYMENT.BENEFITS');
exports.getBenefitsOptions = getBenefitsOptions;
const getEnergyTypeOptions = () => (0, exports.createOptions)(activity_1.EnergyType, 'TOPICS:ENERGY.ENERGY_TYPES');
exports.getEnergyTypeOptions = getEnergyTypeOptions;
const getAllEnergyTypeOptions = () => (0, exports.createOptions)(activity_1.EnergyTypeReduction, 'TOPICS:ENERGY.ENERGY_TYPES');
exports.getAllEnergyTypeOptions = getAllEnergyTypeOptions;
const getBusinessMetricOptions = () => (0, exports.createOptions)(activity_1.BusinessMetric, 'TOPICS:ENERGY.BUSINESS_METRICS');
exports.getBusinessMetricOptions = getBusinessMetricOptions;
const getBasisForCalculationOptions = () => (0, exports.createOptions)(activity_1.EnergyBasisCalculation, 'TOPICS:ENERGY.CALCULATION_BASIS');
exports.getBasisForCalculationOptions = getBasisForCalculationOptions;
const getEnergyReductionSourceOptions = () => (0, exports.createOptions)(activity_1.EnergyReductionSource, 'TOPICS:ENERGY.REDUCTION_SOURCES');
exports.getEnergyReductionSourceOptions = getEnergyReductionSourceOptions;
const getEnergyOperationOptions = () => (0, exports.createOptions)(activity_1.EnergyOperation, 'TOPICS:ENERGY.OPERATIONS');
exports.getEnergyOperationOptions = getEnergyOperationOptions;
const getEnergyActivityOptions = () => (0, exports.createOptions)(activity_1.EnergyActivity, 'TOPICS:ENERGY.ACTIVITY_TYPES');
exports.getEnergyActivityOptions = getEnergyActivityOptions;
const getEnergyActivityCategoriesOptions = () => (0, exports.createOptions)(activity_1.EnergyActivityCategories, 'TOPICS:ENERGY.ACTIVITY_CATEGORIES');
exports.getEnergyActivityCategoriesOptions = getEnergyActivityCategoriesOptions;
const getEnergyEnergyFuelTypeOptions = () => (0, exports.createOptions)(emissions_1.EnergyFuelType, 'ENERGY_EMISSION_FACTOR.FUEL_TYPE_OPTIONS');
exports.getEnergyEnergyFuelTypeOptions = getEnergyEnergyFuelTypeOptions;
const getTrainingEmployeeCategoryOptions = () => (0, exports.createOptionsFromValues)(training_employee_category_1.TrianingEmployeeCategory, 'TOPICS:TRAINING_AND_EDUCATION.EMPLOYEE_CATEGORY_ENUM');
exports.getTrainingEmployeeCategoryOptions = getTrainingEmployeeCategoryOptions;
const getTrainingProgramOptions = () => (0, exports.createOptionsFromValues)(training_program_type_1.TrainingProgramType, 'TOPICS:TRAINING_AND_EDUCATION.TRAINING_PROGRAMS.TRAINING_PROGRAM_TYPE_ENUM');
exports.getTrainingProgramOptions = getTrainingProgramOptions;
const getTransitionAssistanceProgramOptions = () => (0, exports.createOptionsFromValues)(transition_assistance_program_type_1.TransitionAssistanceProgramType, 'TOPICS:TRAINING_AND_EDUCATION.TRAINING_PROGRAMS.TRANSITION_ASSISTANCE_PROGRAM_TYPE_ENUM');
exports.getTransitionAssistanceProgramOptions = getTransitionAssistanceProgramOptions;
const typeOfEmployeeOptions = () => (0, exports.createOptions)(activity_1.TypeOfEmployee, 'TOPICS:GENERAL_DISCLOSURE.EMPLOYEES_BREAKDOWN_FORM.TYPE_OF_EMPLOYEES');
exports.typeOfEmployeeOptions = typeOfEmployeeOptions;
const typeOfWorkOptions = () => (0, exports.createOptions)(activity_1.TypeOfWork, 'TOPICS:GENERAL_DISCLOSURE.NON_EMPLOYEES_BREAKDOWN_FORM.TYPE_OF_WORKS');
exports.typeOfWorkOptions = typeOfWorkOptions;
const contractualRelationshipOptions = () => (0, exports.createOptions)(activity_1.ContractualRelationship, 'TOPICS:GENERAL_DISCLOSURE.NON_EMPLOYEES_BREAKDOWN_FORM.CONTRACTUAL_RELATIONSHIPS');
exports.contractualRelationshipOptions = contractualRelationshipOptions;
const thirdPartyDescription = () => (0, exports.createOptions)(activity_1.ThirdPartyDescription, 'TOPICS:GENERAL_DISCLOSURE.NON_EMPLOYEES_BREAKDOWN_FORM.THIRD_PARTY_DESCRIPTIONS');
exports.thirdPartyDescription = thirdPartyDescription;
const typeofWorker = () => (0, exports.createOptions)(activity_1.TypeOfWorker, 'TOPICS:GENERAL_DISCLOSURE.NON_EMPLOYEES_BREAKDOWN_FORM.TYPE_OF_WORKERS');
exports.typeofWorker = typeofWorker;
const committeeTypeOptions = () => (0, exports.createOptions)(activity_1.CommitteeType, 'TOPICS:GENERAL_DISCLOSURE.GOVERNANCE_STRUCTURE_AND_COMPOSITION_BREAKDOWN_FORM.COMMITTEE_TYPES');
exports.committeeTypeOptions = committeeTypeOptions;
const underRepresentedGroupsDescriptionOptions = () => (0, exports.createOptions)(activity_1.UnderRepresentedGroupsDescription, 'TOPICS:GENERAL_DISCLOSURE.GOVERNANCE_STRUCTURE_AND_COMPOSITION_BREAKDOWN_FORM.UNDER_REPRESENTED_GROUPS_DESCRIPTIONS');
exports.underRepresentedGroupsDescriptionOptions = underRepresentedGroupsDescriptionOptions;
const competencyOptions = () => (0, exports.createOptions)(activity_1.Competency, 'TOPICS:GENERAL_DISCLOSURE.GOVERNANCE_STRUCTURE_AND_COMPOSITION_BREAKDOWN_FORM.COMPETENCYS');
exports.competencyOptions = competencyOptions;
const waterSourceOptions = () => (0, exports.createOptions)(water_source_1.WaterSource, 'TOPICS:WATER_AND_EFFLUENTS.WATER_SOURCE');
exports.waterSourceOptions = waterSourceOptions;
const waterAreaOptions = () => (0, exports.createOptions)(water_area_1.WaterArea, 'TOPICS:WATER_AND_EFFLUENTS.WATER_AREA');
exports.waterAreaOptions = waterAreaOptions;
const waterCategoryOptions = () => (0, exports.createOptions)(water_category_1.WaterCategory, 'TOPICS:WATER_AND_EFFLUENTS.WATER_CATEGORY');
exports.waterCategoryOptions = waterCategoryOptions;
const treatmentLevelOptions = () => (0, exports.createOptions)(treatment_level_1.TreatmentLevel, 'TOPICS:WATER_AND_EFFLUENTS.TREATMENT_LEVEL');
exports.treatmentLevelOptions = treatmentLevelOptions;
const renewableStatusOptions = () => (0, exports.createOptions)(renewable_status_1.RenewableStatus, 'TOPICS:MATERIALS.RENEWABLE_STATUS');
exports.renewableStatusOptions = renewableStatusOptions;
const sourceOfMaterialOptions = () => (0, exports.createOptions)(source_of_material_1.SourceOfMaterial, 'TOPICS:MATERIALS.SOURCE_OF_MATERIAL');
exports.sourceOfMaterialOptions = sourceOfMaterialOptions;
const measurementMethodOptions = () => (0, exports.createOptions)(measurement_method_1.MeasurementMethod, 'TOPICS:MATERIALS.MEASUREMENT_METHOD');
exports.measurementMethodOptions = measurementMethodOptions;
const massAndVolumeUnitsOptions = () => (0, exports.createOptions)(type_1.MassAndVolumeUnits);
exports.massAndVolumeUnitsOptions = massAndVolumeUnitsOptions;
const materialCategoryOptions = () => (0, exports.createOptions)(material_category_1.MaterialCategory, 'TOPICS:MATERIALS.MATERIAL_CATEGORY');
exports.materialCategoryOptions = materialCategoryOptions;
const workCategoryOptions = () => (0, exports.createOptions)(activity_1.WorkerCategory, 'TOPICS:MARKET_PRESENCE.WAGE_RATIO.WORKER_CATEGORIES');
exports.workCategoryOptions = workCategoryOptions;
const typeOfLegalActionOptions = () => (0, exports.createOptions)(activity_1.TypeOfLegalAction, 'TOPICS:ANTI_COMPETITIVE_BEHAVIOR.LEGAL_ACTIONS_FOR_ANTI_COMPETITIVE_BEHAVIOUR_ANTI_TRUST_AND_MONOPOLY_PRACTICES.TYPE_OF_LEGAL_ACTIONS');
exports.typeOfLegalActionOptions = typeOfLegalActionOptions;
const typeOfBenefitPlanOptions = () => (0, exports.createOptions)(activity_1.TypeOfBenefitPlan, 'TOPICS:ECONOMIC_PERFORMANCE.TYPE_OF_BENEFIT_PLAN');
exports.typeOfBenefitPlanOptions = typeOfBenefitPlanOptions;
const planCoverageOptions = () => (0, exports.createOptions)(activity_1.PlanCoverage, 'TOPICS:ECONOMIC_PERFORMANCE.PLAN_COVERAGE');
exports.planCoverageOptions = planCoverageOptions;
const fundingStatusOptions = () => (0, exports.createOptions)(activity_1.FundingStatus, 'TOPICS:ECONOMIC_PERFORMANCE.FUNDING_STATUS');
exports.fundingStatusOptions = fundingStatusOptions;
const participationLevelOptions = () => (0, exports.createOptions)(activity_1.ParticipationLevel, 'TOPICS:ECONOMIC_PERFORMANCE.PARTICIPATION_LEVEL');
exports.participationLevelOptions = participationLevelOptions;
const typeOfAssistanceOptions = () => (0, exports.createOptions)(activity_1.TypeOfAssistance, 'TOPICS:ECONOMIC_PERFORMANCE.TYPE_OF_ASSISTANCE');
exports.typeOfAssistanceOptions = typeOfAssistanceOptions;
const durationOptions = () => (0, exports.createOptions)(activity_1.Duration, 'TOPICS:ECONOMIC_PERFORMANCE.DURATION');
exports.durationOptions = durationOptions;
const riskOpportunityTypesOptions = () => (0, exports.createOptions)(activity_1.RiskOpportunityTypes, 'TOPICS:ECONOMIC_PERFORMANCE.RISK_OPPORTUNITY_TYPES');
exports.riskOpportunityTypesOptions = riskOpportunityTypesOptions;
const timeFrameOptions = () => (0, exports.createOptions)(activity_1.TimeFrame, 'TOPICS:ECONOMIC_PERFORMANCE.TIME_FRAME');
exports.timeFrameOptions = timeFrameOptions;
const impactOptions = () => (0, exports.createOptions)(activity_1.Impacts, 'TOPICS:ECONOMIC_PERFORMANCE.IMPACTS');
exports.impactOptions = impactOptions;
const likelihoodOptions = () => (0, exports.createOptions)(activity_1.Likelihoods, 'TOPICS:ECONOMIC_PERFORMANCE.LIKELIHOODS');
exports.likelihoodOptions = likelihoodOptions;
const impactMagnitudeOptions = () => (0, exports.createOptions)(activity_1.ImpactMagnitude, 'TOPICS:ECONOMIC_PERFORMANCE.IMPACT_MAGNITUDE');
exports.impactMagnitudeOptions = impactMagnitudeOptions;
const getInvestmentCategory = () => (0, exports.createOptions)(type_1.investmentCategory, 'TOPICS:INDIRECT_ECONOMIC_IMPACTS.INVESTMENT_CATEGORY');
exports.getInvestmentCategory = getInvestmentCategory;
const getProjectStatus = () => (0, exports.createOptions)(type_1.ProjectStatus, 'TOPICS:INDIRECT_ECONOMIC_IMPACTS.PROJECT_STATUS');
exports.getProjectStatus = getProjectStatus;
const getInvestmentTypes = () => (0, exports.createOptions)(type_1.InvestmentTypes, 'TOPICS:INDIRECT_ECONOMIC_IMPACTS.INVESTMENT_TYPES');
exports.getInvestmentTypes = getInvestmentTypes;
const getBeneficiaries = () => (0, exports.createOptions)(type_1.Beneficiaries, 'TOPICS:INDIRECT_ECONOMIC_IMPACTS.BENEFICIARIES_LIST');
exports.getBeneficiaries = getBeneficiaries;
const getImpactType = () => (0, exports.createOptions)(type_1.ImpactType, 'TOPICS:INDIRECT_ECONOMIC_IMPACTS.IMPACT_TYPES');
exports.getImpactType = getImpactType;
const getTypeOfOperation = () => (0, exports.createOptions)(type_1.TypeOfOperation, 'TOPICS:FREEDOM_OF_ASSOCIATION.TYPE_OF_OPERATION');
exports.getTypeOfOperation = getTypeOfOperation;
